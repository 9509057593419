import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    doFetchScenarios,
    listLightningWindfarms,
    selectAllLightningTurbinesByWindfarm,
    selectLightningWindfarms,
    selectScenarios,
    selectTurbinesAtRiskByWindfarm,
    selectTurbinesMarkedForInspectionByWindfarm,
    selectTurbinesNoRiskByWindfarm,
    subscribeToScenario,
    unsubscribeToScenario,
    getPingSensorData
} from "../../../flow/lightningFlow";
import './lightning-windfarm-page.css'
import {toPrettyDateStr, toPrettyTimeSince, toStandardWplTimestamp} from "../../../prettyDate";
import {lassieDashboardAPI, lightningAPI} from "../../../api";
import ListOfTurbines from "../list-of-turbines/ListOfTurbines";
import ManageLightningRisk from "../manage-lightning-risk/ManageLightningRisk";
import ManageLightningInspection from "../manage-lightning-inspection/ManageLightningInspection";
import InfoCards from "../../../components/info-card/InfoCard";
import Card from "../../../components/card/Card";
import WplButton from "../../../components/wpl-button/WplButton";
import CenteredPopup from "../../../components/centered-popup/CenteredPopup";
import LightningScenarioSettings from "../../lightning-settings/lightning-scenario-settings/LightningScenarioSettings";
import OlMap from "../../../components/ol-map/OlMap";
import {
    timeFormat,
    select,
    line,
    curveCardinal,
    curveLinear,
    scaleLinear,
    axisBottom,
    axisLeft,
} from "d3";
import WplInput from "../../../components/wpl-input/WplInput";

export default function LightningWindfarmPage() {
    const [wfStatistics, setWfStatistics] = useState(null);
    const [LatestDataAcquisition, setLatestDataAcquisition] = useState(null);
    const [showScenarioSettings, setShowScenarioSettings] = useState(false);

    const [riskTimeline, setRiskTimeline] = useState(null);
    const [userActions, setUserActions] = useState(null);
    const [selectedTimeline, setSelectedTimeline] = useState('');

    const scenarios = useSelector(selectScenarios)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let {windfarm_id} = useParams();
    if (windfarm_id) {
        windfarm_id = parseInt(windfarm_id)
    }
    const windfarms = useSelector(selectLightningWindfarms)
    const turbines = useSelector(selectAllLightningTurbinesByWindfarm(parseInt(windfarm_id)))

    const turbinesNoRisk = useSelector(selectTurbinesNoRiskByWindfarm(windfarm_id))
    const turbinesAtRisk = useSelector(selectTurbinesAtRiskByWindfarm(windfarm_id))
    const turbineToBeInspected = useSelector(selectTurbinesMarkedForInspectionByWindfarm(windfarm_id))

    useEffect(() => {
        if (!windfarm_id) return;
        // no endpoint for fetching specific windfarm at the moment
        dispatch(listLightningWindfarms());
        lightningAPI.getStatistics({windfarm_id}).then(setWfStatistics)
        lightningAPI.getLatestDataAcquisition(windfarm_id).then(setLatestDataAcquisition)
        dispatch(doFetchScenarios(windfarm_id))

        lightningAPI.listWindfarmRisktimeline(windfarm_id).then(setRiskTimeline)
        lassieDashboardAPI
            .listWindfarmsHistoricUserAction(windfarm_id, toStandardWplTimestamp(new Date(2021, 0, 1)), toStandardWplTimestamp(new Date()))
            .then(setUserActions)
    }, [dispatch, windfarm_id]);

    const windfarm = useMemo(() => {
        if (!windfarms || !windfarm_id) return null;
        return windfarms.find(wf => wf.id === parseInt(windfarm_id))
    }, [windfarms, windfarm_id]);

    const svgRef = useRef();
    const riskGraphRef = useRef();

    useEffect(() => {
        if (!svgRef.current || !riskGraphRef.current || !riskTimeline) return;
        if (!selectedTimeline) return;

        const graph = riskTimeline.lines.find(line => line.name === selectedTimeline)

        const svg = select(svgRef.current);
        const svgWrapper = select(riskGraphRef.current);

        const pixelWidth = 900;
        const pixelHeight = 350;

        //scales
        const xScale = scaleLinear()
            .domain([new Date(riskTimeline.min_x), new Date(riskTimeline.max_x)])
            .range([0, pixelWidth]);

        const maxY = graph.max_y * 1.2
        const yScale = scaleLinear()
            .domain([riskTimeline.min_y, maxY])
            .range([pixelHeight, 0]);

        //axes
        const xAxis = axisBottom(xScale).ticks(7, "s").tickFormat(timeFormat("%b %d")); // %b %d %H:%M
        svg.select(".x-axis").call(xAxis);

        const yAxis = axisLeft(yScale);
        svg.select(".y-axis").style("transform", "translateX(0px)").call(yAxis);

        //line generator
        const dLine = line()
            .x(d => xScale(new Date(d.x)))
            .y(d => yScale(d.y))
            .curve(curveLinear);

        svg
            .selectAll(`.line-${graph.id}`)
            .data([graph.data])
            .join("path")
            .attr("class", "line")
            .attr("d", dLine)
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", "2px");

        // draw x axis
        svg
            .selectAll(".x-axis")
            .data([0])
            .join("g")
            .attr("class", "x-axis")
            .attr("transform", "translate(0, 350)")
            .call(xAxis);

        // draw y axis
        svg
            .selectAll(".y-axis")
            .data([0])
            .join("g")
            .attr("class", "y-axis")
            .call(yAxis);

        // display vertical and horizontal guide lines
        svgWrapper
            .append("div")
            .attr('class', 'vertical')
            .style("pointer-events", "none")
            .style("position", "absolute")
            .style("width", "1px")
            .style("top", "0px")
            .style("bottom", "50px")
            .style('display', 'none')
            .style("background", "black");

        svgWrapper
            .append("div")
            .attr('class', 'horizontal')
            .style("pointer-events", "none")
            .style("position", "absolute")
            .style("height", "1px")
            .style("left", "0px")
            .style("right", "50px")
            .style('display', 'none')
            .style("background", "black");

        // draw zones
        const zones = [0.3, 0.6, maxY]
        const zoneColors = [
            "rgba(136, 177, 139, 0.3)",
            "rgba(230, 157, 115, 0.3)",
            "rgba(212, 106, 106, 0.3)",
        ];
        let prevZone = riskTimeline.min_y;
        zones.forEach((zone, idx) => {
            svgWrapper
                .append("div")
                .attr('class', 'zone')
                .style("position", "absolute")
                .style("left", "0px")
                .style("right", "50px")
                .style("top", yScale(zone) + "px")
                .style("height", yScale(prevZone) - yScale(zone) + "px")
                .style("background", zoneColors[idx]);

            prevZone = zone;
        });

        svgWrapper.on("mousemove", e => {
            const boundingRect = svgWrapper.node().getBoundingClientRect();
            const vert = svgWrapper.selectAll('.vertical');
            const hori = svgWrapper.selectAll('.horizontal');

            if (!['zone', 'line-legend'].includes(e.target.className)) {
                vert.style("display", "none")
                hori.style("display", "none")
                return;
            }
            let mousex = e.clientX - boundingRect.left;
            vert.style("left", mousex + "px")
                .style("display", "block");

            let mousey = e.clientY - boundingRect.top;
            hori.style("top", mousey + "px")
                .style("display", "block");
        })

        svgWrapper.on("mouseleave", e => {
            svgWrapper.selectAll('.vertical').style("display", "none")
            svgWrapper.selectAll('.horizontal').style("display", "none")
        })

        return () => {
            svgWrapper.selectAll(".vertical").remove();
            svgWrapper.selectAll(".horizontal").remove();
            svg
                .selectAll('.line')
                .remove();

            svgWrapper
                .selectAll('.zone')
                .remove();
        }
    }, [riskTimeline, userActions, svgRef.current, riskGraphRef.current, selectedTimeline]);

    return (<div className='lightning-windfarm-page'>
        <CenteredPopup showPopup={showScenarioSettings} closePopup={setShowScenarioSettings}>
            <LightningScenarioSettings can_edit={false} specific_windfarm_id={parseInt(windfarm_id)} />
        </CenteredPopup>

        <h1 style={{ marginBottom: 0 }}>{windfarm && windfarm.name}</h1>
        <p style={{ color: 'dimgrey', marginTop: 0, marginBottom: 20 }}>Latest data
            acquisition: {toPrettyTimeSince(new Date(LatestDataAcquisition))}</p>

            <InfoCards statistics={wfStatistics}/>

        <OlMap key='main-map'
            lightning_strikes={[...turbines.map(t => t.turbine_lightning)].flat()}
            turbines={turbines} />
        <div className='list-wrapper'>
            <ListOfTurbines turbines={turbinesAtRisk}
                title={'Turbines at risk'}
                PopupComponent={ManageLightningRisk}
                buttonText={'Manage risk'} />
            <div className='grower' />
            <ListOfTurbines turbines={turbineToBeInspected}
                title={'Marked for inspection'}
                PopupComponent={ManageLightningInspection}
                buttonText={'Manage pending turbines'}
                hideRisk={true}
            />
            <div className='grower' />
            <ListOfTurbines turbines={turbinesNoRisk}
                title={'Managed turbines'}
                PopupComponent={ManageLightningRisk}
                buttonText={'Details'}
                hideRisk={true}
            />
        </div>

        <WplButton className='complete-turbine-list-btn' value='Complete Turbine List'
            onClick={_ => navigate('complete-turbine-list')} />

        {userActions && userActions.length > 0 && <Card className='risk-graph-card'>
            <h1>Your risk timeline</h1>
            {riskTimeline &&
                <WplInput
                    title='Select turbine'
                    value={selectedTimeline}
                    onChanged={setSelectedTimeline}
                    options={riskTimeline.lines.map(line => line.name).sort((a, b) => a.localeCompare(b))}
                    onlySelectableOptions
                    style={{ width: '300px' }}
                />
            }
            <div className='risk-graph' style={{ opacity: selectedTimeline ? '1' : '0' }} ref={riskGraphRef}>
                <svg ref={svgRef}>
                </svg>
                <div className='line-legend'>
                    {/*<div className='line-legend-item' title="If you did nothing to mitigate risk">*/}
                    {/*    <div className='line-legend-color' style={{background: '#989696'}}/>*/}
                    {/*    <label>Potential total risk</label>*/}
                    {/*</div>*/}
                    <div className='line-legend-item' title="Your theoretical risk">
                        <div className='line-legend-color' style={{ background: 'black' }} />
                        <label>Actual risk</label>
                    </div>
                    <div className='zone-legend-item' title="High risk zone">
                        <div className='zone-legend-color' style={{ background: 'rgba(212, 106, 106, 0.8)' }} />
                        <label>High risk zone</label>
                    </div>
                    <div className='zone-legend-item' title="Medium risk zone">
                        <div className='zone-legend-color' style={{ background: 'rgba(230, 157, 115, 0.8)' }} />
                        <label>Medium risk zone</label>
                    </div>
                    <div className='zone-legend-item' title="Low risk zone">
                        <div className='zone-legend-color' style={{ background: 'rgba(136, 177, 139, 0.8)' }} />
                        <label>Low risk zone</label>
                    </div>
                </div>
            </div>
            {selectedTimeline && <>
                <p>
                    Displays your running risk. The total risk is the theoretical risk of lightning strikes on the
                    selected turbine.
                </p>
                <p>
                    The y-axis is a measure of the accumulated risk of lightning strikes within the collection area of the
                    turbine.
                </p>
                <p>
                    To lower your 'actual risk', mark turbines as inspected.
                </p>
                <p>We call this number <abbr title='Theoretical Lightning Attachment'>TLA</abbr></p>
            </>}
        </Card>}

        <Card className='list-of-windfarm-scenarios'>
            <h1>Active scenarios</h1>
            <div className='scenario-card-list'>
                {scenarios.map(scenario => <div key={scenario.id} className='scenario-card'>
                    <div className='sub-wrapper'>
                        <label htmlFor={`sub-id-${scenario.id}`}>Subscribed</label>
                        <input
                            id={`sub-id-${scenario.id}`}
                            onChange={e => {
                                if (e.target.checked) {
                                    dispatch(subscribeToScenario({ scenario_id: scenario.id, frequency: 'daily' }))
                                } else {
                                    dispatch(unsubscribeToScenario({ scenario_id: scenario.id, frequency: 'daily' }))
                                }
                            }} checked={scenario.subscriptions.some(sub => sub.frequency === 'daily')}
                            type='checkbox' />
                    </div>
                    <div>
                        <h3>{scenario.title}</h3>
                        <label>{scenario.immediate_action}</label>
                    </div>
                </div>)}
            </div>
            <WplButton value='More information' className='complete-turbine-list-btn'
                onClick={_ => setShowScenarioSettings(true)} />
        </Card>
    </div>)
}
