import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const ComposedChartComponent = ({ monthlyData, averageData, maxLightningCount }) => {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const calculateYAxisMax = (maxCount) => {
    const roundTo = maxCount > 50 ? 50 : 10;
    return Math.ceil(maxCount / roundTo) * roundTo;
  };

  const formattedMonthlyData = monthlyData.map(d => ({
    ...d,
    month: months[d.month - 1],
    monthly_lightning_count: d.lightning_count
  }));

  const formattedAverageData = averageData.map(d => ({
    ...d,
    month: months[d.month - 1],
    average_lightning_count: Math.round(d.lightning_count)
  }));


  const mergedData = formattedMonthlyData.map((monthData, index) => ({
    ...monthData,
    average_lightning_count: formattedAverageData[index]?.average_lightning_count || 0
  }));


  const yAxisMax = calculateYAxisMax(maxLightningCount);

  return (
    <ResponsiveContainer  height={500}>
      <ComposedChart data={mergedData} margin={{ left: 30, bottom:30 }}>
        <CartesianGrid stroke="#0000001a" vertical={false} />
        <XAxis dataKey="month" />
        <YAxis label={{ value: 'Lightning Strikes', angle: -90, position: 'insideLeft', dx: -25 }} domain={[0, yAxisMax]}
        />
        <Tooltip
          formatter={(value, name) => {
            if (name === 'Monthly Lightning Strikes') {
              return [`${value}`, 'Monthly Lightning Strikes'];
            } else if (name === 'Average Lightning Strikes') {
              return [`${value}`, 'Average Lightning Strikes'];
            }
            return [`${value}`];
          }}
        />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          wrapperStyle={{ paddingTop: 20 }} 
        />
        <Bar
          dataKey="monthly_lightning_count"
          barSize={40}
          fill="#2e4e63"
          name="Monthly Lightning Strikes"
          isAnimationActive={true}
          animationBegin={0}
          animationDuration={1500}
          animationEasing="ease-in-out"
          radius={[5, 5, 0, 0]}
        />
        <Line
          type="monotone"
          dataKey="average_lightning_count"
          stroke="#a52a2a"
          dot={true}
          name="Average Lightning Strikes"
          isAnimationActive={true}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ComposedChartComponent;
