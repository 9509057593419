import React, {useCallback, useEffect, useRef, useState} from 'react';
import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import {Fill, Icon, Stroke, Style} from "ol/style";
import CircleStyle from "ol/style/Circle";
import Text from "ol/style/Text";

export default () => {
    const [map, setMap] = useState(null);
    const [initialized, setInitialized] = useState(null);

    const [initialSourceRender, setInitialSourceRender] = useState(null);

    const mapElement = useRef();

    const turbineStyling = useCallback((f, map) => {
        if (!map) {
            return new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({ color: 'gray' }),
                    stroke: new Stroke({ color: 'white', width: 1 }),
                }),
                text: new Text({
                    font: '14px sans-serif',
                    fill: new Fill({ color: 'white' }),
                    backgroundFill: new Fill({ color: 'black' }),
                    stroke: new Stroke({ color: 'black', width: 1 }),
                    text: '',
                    offsetY: 24,
                    padding: [2, 0, 0, 2]
                })
            });
        }
    
        return {
            normal: () => new Style({
                image: new Icon({
                    src: f.get('status_icon'),
                    opacity: 1,
                    radius: 7,
                    width: 30,
                }),
                fill: new CircleStyle({
                    radius: 100,
                    fill: new Fill({color: 'red'}),
                    stroke: new Stroke({color: '#000', width: 1}),
                }),
                text: new Text({
                    font: '14px sans-serif',
                    fill: new Fill({color: 'white'}),
                    backgroundFill: new Fill({color: 'black'}),
                    stroke: new Stroke({color: 'black', width: 1}),
                    text: map.getView().getZoom() > 12.5 ? f.get('name') : '',
                    offsetY: 24,
                    padding: [2, 0, 0, 2]
                })
            }),
            hover: () => new Style({
                image: new Icon({
                    src: f.get('status_icon'),
                    opacity: 0.8,
                    radius: 7,
                    width: 30,
                }),
                fill: new CircleStyle({
                    radius: 100,
                    fill: new Fill({color: 'red'}),
                    stroke: new Stroke({color: '#000', width: 1}),
                }),
                text: new Text({
                    opacity: 0.8,
                    font: '14px sans-serif',
                    fill: new Fill({color: 'black'}),
                    backgroundFill: new Fill({color: 'white'}),
                    stroke: new Stroke({color: 'white', width: 1}),
                    text: map.getView().getZoom() > 12.5 ? f.get('name') : '',
                    offsetY: 24,
                    padding: [2, 0, 0, 2]
                })
            }),
            selected: () => new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({color: 'black'}),
                    stroke: new Stroke({color: 'white', width: 3}),
                }),
                text: new Text({
                    font: '14px sans-serif',
                    fill: new Fill({color: 'white'}),
                    backgroundFill: new Fill({color: 'black'}),
                    stroke: new Stroke({color: 'black', width: 1}),
                    text: map.getView().getZoom() > 12.5 ? f.get('name') : '',
                    offsetY: 24,
                    padding: [2, 0, 0, 2]
                })
            }),
        };
    }, []);
    

    useEffect(() => {
        if (!mapElement.current || !initialSourceRender || initialized) return;

        const initTurbineLayer = new VectorLayer({
            source: initialSourceRender
        })
        const initialMap = new Map({
            target: mapElement.current,
            layers: [],
            view: new View({
                projection: 'EPSG:3857',
                center: [0, 0],
                zoom: 6,
            }),
            controls: [],
        })
        initialMap.getView().fit(initTurbineLayer.getSource().getExtent(), {
            padding: [50, 50, 50, 50],
            maxZoom: 14,
        })
        setInitialized(true);
        setMap(initialMap);
    }, [mapElement.current, initialSourceRender, initialized]);

    return {
        map,
        mapElement,
        initialized,
        setInitialSourceRender,
        turbineStyling
    }
}
