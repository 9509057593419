import { probabilityToString } from "../../page/lightning/probabilityToString";

export const icons_at_risk = {
    High: 'data:image/svg+xml;charset=utf-8,<svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">' +
        '    <circle fill="white" r="8" cx="16" cy="20" />' +
        '    <path fill="rgb(174, 0, 6)" d="M29.83,26.42l-6.37-11-6.38-11a1.25,1.25,0,0,0-2.16,0L8.54,15.38l-6.37,11a1.25,1.25,0,0,0,1.08,1.87h25.5A1.25,1.25,0,0,0,29.83,26.42ZM16,24.58A1.58,1.58,0,1,1,17.58,23,1.58,1.58,0,0,1,16,24.58Zm1.14-7.51a8.45,8.45,0,0,0-.53,2.73.61.61,0,0,1-1.22,0,8.45,8.45,0,0,0-.53-2.73,10.35,10.35,0,0,1-.74-3.25,1.88,1.88,0,0,1,3.76,0A10.35,10.35,0,0,1,17.14,17.07Z"/>' +
        '</svg>',
    Medium: 'data:image/svg+xml;charset=utf-8,<svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">' +
        '    <circle fill="white" r="8" cx="16" cy="20" />' +
        '    <path fill="orange" d="M29.83,26.42l-6.37-11-6.38-11a1.25,1.25,0,0,0-2.16,0L8.54,15.38l-6.37,11a1.25,1.25,0,0,0,1.08,1.87h25.5A1.25,1.25,0,0,0,29.83,26.42ZM16,24.58A1.58,1.58,0,1,1,17.58,23,1.58,1.58,0,0,1,16,24.58Zm1.14-7.51a8.45,8.45,0,0,0-.53,2.73.61.61,0,0,1-1.22,0,8.45,8.45,0,0,0-.53-2.73,10.35,10.35,0,0,1-.74-3.25,1.88,1.88,0,0,1,3.76,0A10.35,10.35,0,0,1,17.14,17.07Z"/>' +
        '</svg>',
    Low: 'data:image/svg+xml;charset=utf-8,<svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">' +
        '    <circle fill="white" r="8" cx="15" cy="20" />' +
        '    <path fill="green" d="M29.83,26.42l-6.37-11-6.38-11a1.25,1.25,0,0,0-2.16,0L8.54,15.38l-6.37,11a1.25,1.25,0,0,0,1.08,1.87h25.5A1.25,1.25,0,0,0,29.83,26.42ZM16,24.58A1.58,1.58,0,1,1,17.58,23,1.58,1.58,0,0,1,16,24.58Zm1.14-7.51a8.45,8.45,0,0,0-.53,2.73.61.61,0,0,1-1.22,0,8.45,8.45,0,0,0-.53-2.73,10.35,10.35,0,0,1-.74-3.25,1.88,1.88,0,0,1,3.76,0A10.35,10.35,0,0,1,17.14,17.07Z"/>' +
        '</svg>',
    'Very low': 'data:image/svg+xml;charset=utf-8,<svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">' +
        '    <circle fill="white" r="8" cx="15" cy="20" />' +
        '    <path fill="green" d="M29.83,26.42l-6.37-11-6.38-11a1.25,1.25,0,0,0-2.16,0L8.54,15.38l-6.37,11a1.25,1.25,0,0,0,1.08,1.87h25.5A1.25,1.25,0,0,0,29.83,26.42ZM16,24.58A1.58,1.58,0,1,1,17.58,23,1.58,1.58,0,0,1,16,24.58Zm1.14-7.51a8.45,8.45,0,0,0-.53,2.73.61.61,0,0,1-1.22,0,8.45,8.45,0,0,0-.53-2.73,10.35,10.35,0,0,1-.74-3.25,1.88,1.88,0,0,1,3.76,0A10.35,10.35,0,0,1,17.14,17.07Z"/>' +
        '</svg>',
};

export const icons_at_risk_ping_sensor = {
    High: 'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`
    <svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle fill="white" r="8" cx="16" cy="20"/>
        <path fill="rgb(174, 0, 6)" d="M29.83,26.42l-6.37-11-6.38-11a1.25,1.25,0,0,0-2.16,0L8.54,15.38l-6.37,11a1.25,1.25,0,0,0,1.08,1.87h25.5A1.25,1.25,0,0,0,29.83,26.42ZM16,24.58A1.58,1.58,0,1,1,17.58,23,1.58,1.58,0,0,1,16,24.58Zm1.14-7.51a8.45,8.45,0,0,0-.53,2.73.61.61,0,0,1-1.22,0,8.45,8.45,0,0,0-.53-2.73,10.35,10.35,0,0,1-.74-3.25,1.88,1.88,0,0,1,3.76,0A10.35,10.35,0,0,1,17.14,17.07Z"/>
        <path fill="#FFB636" d="M459.866 218.346l-186.7.701c-4.619.017-7.618-4.861-5.517-8.975L370.845 8.024c3.103-6.075-4.493-11.949-9.592-7.417L39.948 286.141c-4.221 3.751-1.602 10.732 4.045 10.78l170.444 1.457c4.443.038 7.391 4.619 5.583 8.679L133.317 501.73c-2.688 6.035 4.709 11.501 9.689 7.16l320.937-279.725c4.307-3.753 1.637-10.84-4.077-10.819z" transform="scale(0.039) translate(350, -10)"/>
    </svg>`),
    Medium: 'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`
    <svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle fill="white" r="8" cx="16" cy="20"/>
        <path fill="orange" d="M29.83,26.42l-6.37-11-6.38-11a1.25,1.25,0,0,0-2.16,0L8.54,15.38l-6.37,11a1.25,1.25,0,0,0,1.08,1.87h25.5A1.25,1.25,0,0,0,29.83,26.42ZM16,24.58A1.58,1.58,0,1,1,17.58,23,1.58,1.58,0,0,1,16,24.58Zm1.14-7.51a8.45,8.45,0,0,0-.53,2.73.61.61,0,0,1-1.22,0,8.45,8.45,0,0,0-.53-2.73,10.35,10.35,0,0,1-.74-3.25,1.88,1.88,0,0,1,3.76,0A10.35,10.35,0,0,1,17.14,17.07Z"/>
         <path fill="#FFB636" d="M459.866 218.346l-186.7.701c-4.619.017-7.618-4.861-5.517-8.975L370.845 8.024c3.103-6.075-4.493-11.949-9.592-7.417L39.948 286.141c-4.221 3.751-1.602 10.732 4.045 10.78l170.444 1.457c4.443.038 7.391 4.619 5.583 8.679L133.317 501.73c-2.688 6.035 4.709 11.501 9.689 7.16l320.937-279.725c4.307-3.753 1.637-10.84-4.077-10.819z" transform="scale(0.039) translate(350, -10)"/>
    </svg>`),
    Low: 'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`
    <svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle fill="white" r="8" cx="15" cy="20"/>
        <path fill="green" d="M29.83,26.42l-6.37-11-6.38-11a1.25,1.25,0,0,0-2.16,0L8.54,15.38l-6.37,11a1.25,1.25,0,0,0,1.08,1.87h25.5A1.25,1.25,0,0,0,29.83,26.42ZM16,24.58A1.58,1.58,0,1,1,17.58,23,1.58,1.58,0,0,1,16,24.58Zm1.14-7.51a8.45,8.45,0,0,0-.53,2.73.61.61,0,0,1-1.22,0,8.45,8.45,0,0,0-.53-2.73,10.35,10.35,0,0,1-.74-3.25,1.88,1.88,0,0,1,3.76,0A10.35,10.35,0,0,1,17.14,17.07Z"/>
        <path fill="#FFB636" d="M459.866 218.346l-186.7.701c-4.619.017-7.618-4.861-5.517-8.975L370.845 8.024c3.103-6.075-4.493-11.949-9.592-7.417L39.948 286.141c-4.221 3.751-1.602 10.732 4.045 10.78l170.444 1.457c4.443.038 7.391 4.619 5.583 8.679L133.317 501.73c-2.688 6.035 4.709 11.501 9.689 7.16l320.937-279.725c4.307-3.753 1.637-10.84-4.077-10.819z" transform="scale(0.039) translate(350, -10)"/>

        </svg>`),
    'Very low': 'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`
    <svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle fill="white" r="8" cx="15" cy="20"/>
        <path fill="green" d="M29.83,26.42l-6.37-11-6.38-11a1.25,1.25,0,0,0-2.16,0L8.54,15.38l-6.37,11a1.25,1.25,0,0,0,1.08,1.87h25.5A1.25,1.25,0,0,0,29.83,26.42ZM16,24.58A1.58,1.58,0,1,1,17.58,23,1.58,1.58,0,0,1,16,24.58Zm1.14-7.51a8.45,8.45,0,0,0-.53,2.73.61.61,0,0,1-1.22,0,8.45,8.45,0,0,0-.53-2.73,10.35,10.35,0,0,1-.74-3.25,1.88,1.88,0,0,1,3.76,0A10.35,10.35,0,0,1,17.14,17.07Z"/>
    </svg>`)
};


export const icons_marked_for_inspection = {
    'Very low': '/lassie/question_mark_in_orange.png',
    Low: '/lassie/question_mark_in_orange.png',
    Medium: '/lassie/question_mark_in_orange.png',
    High: '/lassie/question_mark_in_orange.png',
};

export const icons_marked_for_inspection_ping_sensor = {
    'Very low': '/lassie/question_mark_in_orange_ping_sensor.png',
    Low: '/lassie/question_mark_in_orange_ping_sensor.png',
    Medium: '/lassie/question_mark_in_orange_ping_sensor.png',
    High: '/lassie/question_mark_in_orange_ping_sensor.png',
};


export const icons_handled = {
    'Very low': 'data:image/svg+xml;charset=utf-8,<svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">' +
        '<circle fill="white" r="10" cx="15" cy="15" />' +
        '<path fill="green" d="M28,6.26A22.16,22.16,0,0,1,16,2,22.16,22.16,0,0,1,4,6.26S1,24.31,16,30C31,24.31,28,6.26,28,6.26ZM13.86,21.11a38.22,38.22,0,0,0-3.63-5.22l1-.16L13.82,17a23.2,23.2,0,0,1,7-6.06h.91S18.56,12.91,13.86,21.11Z"/>' +
        '</svg>',
    Low: 'data:image/svg+xml;charset=utf-8,<svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">' +
        '<circle fill="white" r="10" cx="15" cy="15" />' +
        '<path fill="green" d="M28,6.26A22.16,22.16,0,0,1,16,2,22.16,22.16,0,0,1,4,6.26S1,24.31,16,30C31,24.31,28,6.26,28,6.26ZM13.86,21.11a38.22,38.22,0,0,0-3.63-5.22l1-.16L13.82,17a23.2,23.2,0,0,1,7-6.06h.91S18.56,12.91,13.86,21.11Z"/>' +
        '</svg>',
    Medium: 'data:image/svg+xml;charset=utf-8,<svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">' +
        '<circle fill="white" r="10" cx="15" cy="15" />' +
        '<path fill="green" d="M28,6.26A22.16,22.16,0,0,1,16,2,22.16,22.16,0,0,1,4,6.26S1,24.31,16,30C31,24.31,28,6.26,28,6.26ZM13.86,21.11a38.22,38.22,0,0,0-3.63-5.22l1-.16L13.82,17a23.2,23.2,0,0,1,7-6.06h.91S18.56,12.91,13.86,21.11Z"/>' +
        '</svg>',
    High: 'data:image/svg+xml;charset=utf-8,<svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">' +
        '<circle fill="white" r="10" cx="15" cy="15" />' +
        '<path fill="green" d="M28,6.26A22.16,22.16,0,0,1,16,2,22.16,22.16,0,0,1,4,6.26S1,24.31,16,30C31,24.31,28,6.26,28,6.26ZM13.86,21.11a38.22,38.22,0,0,0-3.63-5.22l1-.16L13.82,17a23.2,23.2,0,0,1,7-6.06h.91S18.56,12.91,13.86,21.11Z"/>' +
        '</svg>',
};

export const icons_handled_ping_sensor = {
    'Very low': 'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`
    <svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle fill="white" r="10" cx="15" cy="15" />
        <path fill="green" d="M28,6.26A22.16,22.16,0,0,1,16,2,22.16,22.16,0,0,1,4,6.26S1,24.31,16,30C31,24.31,28,6.26,28,6.26ZM13.86,21.11a38.22,38.22,0,0,0-3.63-5.22l1-.16L13.82,17a23.2,23.2,0,0,1,7-6.06h.91S18.56,12.91,13.86,21.11Z"/>
        <path fill="#FFB636" d="M459.866 218.346l-186.7.701c-4.619.017-7.618-4.861-5.517-8.975L370.845 8.024c3.103-6.075-4.493-11.949-9.592-7.417L39.948 286.141c-4.221 3.751-1.602 10.732 4.045 10.78l170.444 1.457c4.443.038 7.391 4.619 5.583 8.679L133.317 501.73c-2.688 6.035 4.709 11.501 9.689 7.16l320.937-279.725c4.307-3.753 1.637-10.84-4.077-10.819z" transform="scale(0.039) translate(350, -10)"/>
    </svg>`),
    Low: 'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`
    <svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle fill="white" r="10" cx="15" cy="15" />
        <path fill="green" d="M28,6.26A22.16,22.16,0,0,1,16,2,22.16,22.16,0,0,1,4,6.26S1,24.31,16,30C31,24.31,28,6.26,28,6.26ZM13.86,21.11a38.22,38.22,0,0,0-3.63-5.22l1-.16L13.82,17a23.2,23.2,0,0,1,7-6.06h.91S18.56,12.91,13.86,21.11Z"/>
        <path fill="#FFB636" d="M459.866 218.346l-186.7.701c-4.619.017-7.618-4.861-5.517-8.975L370.845 8.024c3.103-6.075-4.493-11.949-9.592-7.417L39.948 286.141c-4.221 3.751-1.602 10.732 4.045 10.78l170.444 1.457c4.443.038 7.391 4.619 5.583 8.679L133.317 501.73c-2.688 6.035 4.709 11.501 9.689 7.16l320.937-279.725c4.307-3.753 1.637-10.84-4.077-10.819z" transform="scale(0.039) translate(350, -10)"/>
    </svg>`),
    Medium: 'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`
    <svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle fill="white" r="10" cx="15" cy="15" />
        <path fill="green" d="M28,6.26A22.16,22.16,0,0,1,16,2,22.16,22.16,0,0,1,4,6.26S1,24.31,16,30C31,24.31,28,6.26,28,6.26ZM13.86,21.11a38.22,38.22,0,0,0-3.63-5.22l1-.16L13.82,17a23.2,23.2,0,0,1,7-6.06h.91S18.56,12.91,13.86,21.11Z"/>
        <path fill="#FFB636" d="M459.866 218.346l-186.7.701c-4.619.017-7.618-4.861-5.517-8.975L370.845 8.024c3.103-6.075-4.493-11.949-9.592-7.417L39.948 286.141c-4.221 3.751-1.602 10.732 4.045 10.78l170.444 1.457c4.443.038 7.391 4.619 5.583 8.679L133.317 501.73c-2.688 6.035 4.709 11.501 9.689 7.16l320.937-279.725c4.307-3.753 1.637-10.84-4.077-10.819z" transform="scale(0.039) translate(350, -10)"/>
    </svg>`),
    High: 'data:image/svg+xml;charset=utf-8,' +
        encodeURIComponent(`
    <svg width="120" height="120" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <circle fill="white" r="10" cx="15" cy="15" />
        <path fill="green" d="M28,6.26A22.16,22.16,0,0,1,16,2,22.16,22.16,0,0,1,4,6.26S1,24.31,16,30C31,24.31,28,6.26,28,6.26ZM13.86,21.11a38.22,38.22,0,0,0-3.63-5.22l1-.16L13.82,17a23.2,23.2,0,0,1,7-6.06h.91S18.56,12.91,13.86,21.11Z"/>
        <path fill="#FFB636" d="M459.866 218.346l-186.7.701c-4.619.017-7.618-4.861-5.517-8.975L370.845 8.024c3.103-6.075-4.493-11.949-9.592-7.417L39.948 286.141c-4.221 3.751-1.602 10.732 4.045 10.78l170.444 1.457c4.443.038 7.391 4.619 5.583 8.679L133.317 501.73c-2.688 6.035 4.709 11.501 9.689 7.16l320.937-279.725c4.307-3.753 1.637-10.84-4.077-10.819z" transform="scale(0.039) translate(350, -10)"/>
    </svg>`)
};

export function getIconForTurbine(t, most_probable) {
    const riskLevel = probabilityToString(
        most_probable ? t.prob_atleast_one_only_most_likely : t.probability_of_atleast_one
    );
    const hasSensorData = t.ping_measurements && t.ping_measurements.length > 0;

    if (t.status === 'at_risk') {
        if (hasSensorData) {
            return icons_at_risk_ping_sensor[riskLevel];
        } else {
            return icons_at_risk[riskLevel];
        }
    } else if (t.status === 'marked_for_inspection') {
        if (hasSensorData) {
            return icons_marked_for_inspection_ping_sensor[riskLevel];
        } else {
            return icons_marked_for_inspection[riskLevel];
        }
    } else {
        if (hasSensorData) {
            return icons_handled_ping_sensor[riskLevel];
        } else {
            return icons_handled[riskLevel];
        }
    }
}
