export const extractUniqueYears = (data) => [...new Set(data.map(item => item.year))];

export const preprocessMonthlyData = (data, selectedYear) => {
    
    if (!data || data.length === 0) {
        return Array.from({ length: 12 }, (_, i) => ({
            month: i + 1,
            year: selectedYear,
            lightning_count: 0
        }));
    }

        const years = extractUniqueYears(data);
        const defaultYear = years.length ? Math.max(...years) : selectedYear;
        
        if (!selectedYear || !years.includes(selectedYear)) {
            selectedYear = defaultYear;
        }
        
        const monthData = Array.from({ length: 12 }, (_, i) => ({
            month: i + 1,
            year: selectedYear,
            lightning_count: 0
        }));
        
        const filteredData = data.filter(d => d.year === selectedYear);
        
        filteredData.forEach(d => {
            const monthIndex = d.month - 1;
            monthData[monthIndex] = {
                ...monthData[monthIndex],
                lightning_count: d.lightning_count
            };
        });
        
        return monthData;
    }


export const calculateAverageData = (data) => {
    const years = extractUniqueYears(data); 

    const monthTotals = Array.from({ length: 12 }, (_, i) => ({
        month: i + 1,
        total_count: 0,
        count: 0
    }));

    data.forEach(d => {
        const monthIndex = d.month - 1;
        monthTotals[monthIndex].total_count += d.lightning_count;
        monthTotals[monthIndex].count += 1;
    });

    const averageData = monthTotals.map(monthData => ({
        month: monthData.month,
        lightning_count: monthData.total_count / years.length
    }));

    return averageData;
};

export const getMaxLightningCount = (data) => {
    if (data.length === 0 ) return 0;
    return Math.max(...data.map(d => d.lightning_count));
};
