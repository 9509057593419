import React, {useEffect} from 'react';
import './lightning-page-home.css'
import {useDispatch, useSelector} from "react-redux";
import {
    listLightningWindfarms,
    selectLightningWindfarms,
    selectTurbinesAtRisk,
    selectTurbinesMarkedForInspection, selectTurbinesNoRisk,
} from "../../../flow/lightningFlow";
import Card from "../../../components/card/Card";
import {useNavigate} from "react-router-dom";
import WplInput from "../../../components/wpl-input/WplInput";
import WplButton from "../../../components/wpl-button/WplButton";
import WindfarmList from "../../../components/lassie-specific/WindfarmList";


export default function LightningHomePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const windfarms = useSelector(selectLightningWindfarms);

    useEffect(() => {
        dispatch(listLightningWindfarms());
    }, [dispatch]);

    if (windfarms && windfarms.length === 0) {
        return <div className='lightning-page-home' style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
            background: '#fafafa',
            padding: '32px 64px',
            margin: '64px 0'
        }}>
            <h2>Windpowerlab's Lightning Monitoring system: LASSIE</h2>
            <p>Contact us for a demo</p>
            <WplButton value='Back to Winddiagnostics' onClick={_ => navigate('/')} big/>
        </div>
    }

    return (<div className='lightning-page-home'>
        <WplInput
            title='Jump to windfarm'
            className='no-risk-windfarms'
            options={windfarms.map(wf => wf.name)}
            onlySelectableOptions
            onChanged={name => {
                const wf = windfarms.find(w => w.name === name)
                if (wf) {
                    navigate(`windfarm/${wf.id}`)
                }
            }}
        />
        <Card>
            <WindfarmList />
        </Card>
    </div>)
}
